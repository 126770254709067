import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import customAxios from "lib/axios";
import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import { show } from "store/reducers/notifications.reducer";
import {
  changeSaveStatus,
  changeValidationStatus,
  loadStrategy,
  reset,
} from "store/reducers/strategy.reducer";
import { loadRules } from "store/reducers/builder.reducer";

/**
 * Hook used to interact with builder & strateg states
 * for loading, saving, deleting, etc.
 */
function useStrategy() {
  const didMount = useRef(false);
  const dispatch = useAppDispatch();
  const strategy = useAppSelector((state) => state.strategy);
  const builder = useAppSelector((state) => state.builder);
  const userId = localStorage.getItem("user_id");
  const [t] = useTranslation(["errors"]);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    // TODO Update session every 5 seconds.
  }, []);

  /**
   * Effect used to change validation
   * validation & save status if builder changes.
   */
  useEffect(() => {
    // Evade execution on first mount.
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    dispatch(changeValidationStatus({ isValidated: false }));
    dispatch(changeSaveStatus({ saveStatus: false }));
  }, [builder.present, dispatch]);

  /**
   * Function used to save a Strategy into the Database
   */
  const save = async () => {
    try {
      const res = await customAxios.put("/strategies", {
        id: strategy.id,
        title: strategy.title,
        user: userId,
        open: builder.present.rules.filter((rule) => rule.type === "open"),
        close: builder.present.rules.filter((rule) => rule.type === "close"),
        timeframe: strategy.timeframe,
        asset: strategy.ticker,
        startDate: format(strategy.startDate, "yyyy-MM-dd HH:mm:ss"),
        endDate: format(strategy.endDate, "yyyy-MM-dd HH:mm:ss"),
        balance: strategy.balance,
        currency: strategy.currency,
        gain: strategy.gain,
        spread_type: strategy.spread_type,
        spread_value: strategy.spread_value,
        profit: (strategy.gain / strategy.balance) * 100,
        risk: strategy.relativeDrawdown,
        trades_win: strategy.tradesWon,
        trades_loss: strategy.tradesLost,
        success_rate: strategy.successRate,
        gains_only: strategy.gainsOnly,
        loses_only: strategy.losesOnly,
        best_gain: strategy.bestGain,
        worst_loss: strategy.worstLoss,
        gain_loss_rate: strategy.gainLossRate,
        drawdown: strategy.drawdown,
        dailydrawdown: strategy.dailydrawdown,
        relativeDrawdown: strategy.relativeDrawdown,
        runup: strategy.runup,
        dailyrunup: strategy.dailyrunup,
        relativeRunup: strategy.relativeRunup,
        validated: strategy.isValidated,
        image: strategy.image,
      });
      dispatch(changeSaveStatus({ id: res.data.id, saveStatus: true }));
      dispatch(show({ type: "success", msg: t("builder:savedSuccesfully") }));
      setWorking(false);
    } catch (error) {
      setWorking(false);
      throw new Error("Error saving strategy.");
    }
  };

  /**
   * Function used to load a Strategy into the App
   * @param origin S is used for Shared Strategies, M is for User Strategy & H is form Session History
   * @param id Strategy DB id
   */
  const load = async (origin: "S" | "M" | "H", id: number) => {
    setWorking(true);
    try {
      const res = await customAxios.put(`session/${userId}`, {
        origin,
        id,
        userId,
      });
      dispatch(
        loadRules({
          openRules: res.data.openScenario,
          closeRules: res.data.closeScenario,
        })
      );
      dispatch(reset());
      dispatch(
        loadStrategy({
          id: origin === "S" ? null : res.data.id, // TODO Don't return ID from API if is Shared Strategy
          title: res.data.strategyTitle,
          origin: origin,
          ticker: res.data.asset,
          timeframe: res.data.timeframe,
          balance: res.data.balance,
          startDate: res.data.startDate
            ? res.data.startDate
            : new Date("2021-01-01"),
          isShared: origin === "S",
          isValidated: res.data.isValidated > 0,
          validation: res.data.validation,
          spread_type: res.data.spread_type,
          spread_value: res.data.spread_value,
        })
      );
    } catch (e) {
      console.error(e);
      setWorking(false);
      throw new Error("Error loading strategy.");
    }
  };

  return { save, load, working };
}

export default useStrategy;
